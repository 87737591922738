import CommonUtil from "src/shared/CommonUtil";
import apiService from "./apiService";
import moment from "moment";

export function getEventsAPI(date, module_id) {
    const params = {}
    params.sessionId = CommonUtil.getSelectedSession()?.id
    params.schoolId = CommonUtil.getSelectedSchool()
    return apiService({
        url: "/holiday",
        params : {
            ...params,
            date,
            holidayType : "All"
        },
        method: 'GET',
        module_id
    })
}
export function getEventsInMonthAPI(date) {
    const params = {}
    params.sessionId = CommonUtil.getSelectedSession()?.id
    params.schoolId = CommonUtil.getSelectedSchool()
    return apiService({
        url: "/holiday",
        params : {
            ...params,
            startDate : moment(date).startOf('month').format('YYYY-MM-DD'),
            endDate : moment(date).endOf('month').format('YYYY-MM-DD'),
            holidayType : "All"
        },
        method: 'GET',
    })
}

export function addEventAPI(payload={}, module_id) {
    payload.sessionId = CommonUtil.getSelectedSession()?.id
    payload.schoolId = CommonUtil.getSelectedSchool()
    return apiService({
        url: "/holiday",
        data: payload,
        method: 'POST',
        module_id
    })
}

export function updateEventAPI(id, payload={}, module_id) {
    return apiService({
        url: "/holiday/" + id,
        data: payload,
        method: 'PATCH',
        module_id
    })
}

export function deleteEventAPI(id, module_id) {
    return apiService({
        url: "/holiday/"+ id,
        method: 'DELETE',
        module_id
    })
}

export function runHolidayCRONAPI() {
    return apiService({
        url: "/attendance/bulk-attendance-update-on-holiday",
        method: 'GET',
    })
}